<template>
    <div >
        <div class="bg history flex center column">
            <p class="titlee">发展历程</p>
            <p class="titlee2">2006年成立至今</p>
            <div style="width:300px;" class="flex center">
                <p class="time_str" style="width:50%;border-right:1px solid #555" @click="time = 'first'">2006-2015</p>
                <p class="time_str" style="width:50%" @click="time = 'second'">2016-至今</p>
            </div>
            <div class="time_box " style="height:70%" v-if="width>800">
                <div style="height:50%" class="flex around1 top wow bounceIn">
                    <div class="white_bg top_point1" v-for="item in data[time]" :key="time+item.time" v-show="item.top">
                        <p class="title">{{item.time}}</p>
                        <div v-for="content in item.content" :key="content">{{content}}</div>
                        <div class="bg_after"></div>
                    </div>
                    
                </div>
                <div style="height:50%" class="flex around1 bottom time_line wow bounceIn">
                    <div class="white_bg bottom_point1"  v-for="item in data[time]" :key="time+item.time" v-show="!item.top">
                        <div class="bg_before"></div>
                        <p class="title">{{item.time}}</p>
                        <div v-for="content in item.content" :key="content">{{content}}</div>
                    </div>
                    
                </div>
            </div>
            <div class="time_box" v-else>
                <el-timeline>
                    <el-timeline-item :key="item.time" v-for="item in data[time]" :timestamp="item.time" placement="top">
                    <el-card >
                        <p class="title">{{item.time}}</p>
                        <p v-for="content in item.content" :key="content">{{content}}</p>
                    </el-card>
                    </el-timeline-item>
                </el-timeline>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {
            time:'first',
            data:{
                first:[
                        {time:'2006',content:[`2006年1月5日厦门历思科技服务有限公司成立。`,
                        '2006年4月福建历思司法鉴定所获批成立。']},
                        {time:'2007',content:[`福建历思司法鉴定所获批全国首个环境物证鉴定资质 。`,'福建历思司法鉴定所泉州分所获批成立。'],top:true},
                        {time:'2009',content:[`建立法医毒物和文痕检验专业实验室。`]},
                        {time:'2011',content:[`中国首个汉字笔迹学学术团体--厦门笔迹学学会成立。历思为其支撑单位，创会会长、现任会长及学会多名骨干均为鉴定所专家。`],top:true},
                        {time:'2012',content:[`建立法医物证专业实验室`]},
                        {time:'2015',content:[`历思大型综合物证中心建成。`],top:true},
                ],
                second:[
                        {time:'2016',content:[`入选环保部环境损害鉴定评估推荐机构名录。`,'厦门堃晟检测技术有限公司成立 。']},
                        {time:'2017',content:[`历思在厦门金砖国家峰会期间，负责了安保相关的毒品、毒物鉴定工作，为金砖会议提供优质及时的服务。`,'历思承担的《环境损害鉴定评估通用规范》福建省地方标准正式发布，成为国内该领域首个标准。'],top:true},
                        {time:'2018',content:[`历思与福建省漳州招商局经济技术开发区管委会联合申报环保部环境损害鉴定评估技术试点获批。`,'承担厦门首起环境公益诉讼案。']},
                        {time:'2019',content:[`通过了环境损害司法鉴定全项7大类资质的评审。`,'福州堃晟检测技术有限公司成立。'],top:true},
                        {time:'2020',content:[`福州堃晟检测技术有限公司司法鉴定所成立。`]},
                        {time:'2021',content:[`受福建省禁毒委员会办公室委托，历思承担的《生物检材中毒品及其代谢物分析操作规程》福建省地方标准正式发布。`],top:true},
                ],
            }
        }
    },
    computed:{
        width(){
            return this.$store.state.width
        }
    }
}
</script>
<style scoped>
    .bg{
        background:url('../../assets/about/history/1.png');
        min-height:800px;
        width:90%;
        margin: 2rem auto;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
    .history .titlee{
        color:#027DB4;
        font-size:2rem;
        margin-bottom:2rem;
    }
    .history .titlee2{
        margin-bottom:2rem;
    }
    .history .white_bg{
        background: #fff;
        width: 30.3%;
        /* height: 90%; */
        border-radius: 4px;
        position: relative;
        box-shadow: 5px 4px 9px 0px #89898980;
        box-sizing: border-box;
        padding: 20px 10px;
        text-align: left;
        letter-spacing: 2px;
        min-height: 200px;
    }
    .history .white_bg:hover{
        background:#2ca9d9;
        color:#fff;
    }
    .history .white_bg:hover .bg_after{
        border-top:24px solid #2ca9d9;
    }
    .history .bg_after{
        /* content: '';
        display: block; */
        position: absolute;
        bottom: -24px;
        left: 30%;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        border-top: 24px solid #fff;
    }
    .history .white_bg:hover .bg_before{
        border-bottom:24px solid #2ca9d9;
    }
    .history .bg_before{
        /* content: '';
        display: block; */
        position: absolute;
        top: -24px;
        left: 30%;
        border-left: 30px solid transparent;
        border-right: 30px solid transparent;
        border-bottom: 24px solid #fff;
    }
    .time_box{
        width:100%;
        margin-top: 4rem;
        position:relative;
    }
    .time_box .top{
        width: 80%;
        margin-left: 20%;
        margin-bottom: 4rem;
        
    }
    .time_line::after{
        content: '';
        display: block;
        width: 100%;
        margin-left: 22%;
        height: 2px;
        background: rgb(85 85 85 / 50%);
        position: absolute;
        top: -30px;
    }
    .top_point1::after{
        content: '';
        display: block;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        background: rgb(141 134 134 / 50%);
        position: absolute;
        bottom: -24%;
        left: 27%;
        border: 5px solid #e1e1e1;
    }
    .bottom_point1::after{
        content: '';
        display: block;
        width: 17px;
        height: 17px;
        border-radius: 50%;
        background: rgb(141 134 134 / 50%);
        position: absolute;
        top: -43px;
        left: 27%;
        border: 5px solid #e1e1e1;
    }
    .time_box .bottom{
        width: 80%;
        margin-right: 20%;
        position:relative;
    }
    .time_box .title{
        font-weight: bold;
        font-size: 1.1rem;
        margin-bottom: 12px;
    }
    /* .history .bg_after:hover{
        background:#2ca9d9;
    } */
    .el-timeline{
        padding-right: 2rem;
    }
    .content{
        text-align: left;
        text-indent: 2rem;
    }
    .time_str:hover{
        color:#2ca9d9;
        cursor:pointer;
    }
</style>